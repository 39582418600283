import styled from "@emotion/styled"
import header_bg from "../img/header-bg.jpg"
import mid_bg from "../img/midsection.jpg"
import footer_bg from "../img/footer.jpg"

export const Dot = styled("div")`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: white;
    margin: 0.3rem 0;
    transition: 0.4s;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    &.active {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), inset 0 0 0 0.5rem black;
    }
`

export const ScrollWrapper = styled("div")`
    position: fixed;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
`

export const Logo = styled("img")`
    position: absolute;
    top: 3rem;
    left: 3rem;
    max-width: 200px;
    @media screen and (max-width: 768px) {
        max-width: 120px;
        top: 1.5rem;
        left: 1.5rem;
    }
`

export const Header = styled("header")`
    height: 100vh;
    display: flex;
    position: relative;
    background: url(${header_bg});
    background-position: top center;
    background-size: cover;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    color: white;
    align-items: flex-start;
    justify-content: center;
    padding: 20vh 0;
    font-size: 2vw;
    text-align: center;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    p {
        max-width: 50vw;
    }
    @media screen and (max-width: 768px) {
        padding: 10vh 0;
        font-size: 4vw;
        p {
            max-width: 80vw;
        }
    }
`
export const Section = styled("section")`
    height: 100vh;
    display: flex;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    & > div.half {
        height: 100%;
        width: 50%;
        &.imgBox {
            background: url(${mid_bg});
            background-position: center center;
            background-size: cover;
        }
        &.textBox {
            display: flex;
            padding: 0 5vw;
            align-items: center;
            justify-content: center;
            background: #e4e4e4;
            div {
                max-width: 520px;
                width: 100%;
            }
        }
    }

    .isobox {
        display: flex;
        img {
            max-width: 25%;
            margin: 0 5%;
        }
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
        & > div.half {
            width: 100%;

            &.imgBox {
                background-position: center center;
                background-size: cover;
                height: 35%;
            }
            &.textBox {
                height: 65%;
                p,
                ul,
                li {
                    font-size: 0.9rem;
                }
                div {
                    max-width: 520px;
                    width: 100%;
                }
            }
        }
    }
`

export const Footer = styled("footer")`
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    display: flex;
    position: relative;
    background: url(${footer_bg});
    background-position: top center;
    background-size: cover;
    color: white;
    align-items: flex-start;
    justify-content: center;
    padding: 20vh 0;
    font-size: 2vw;
    text-align: center;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    p {
        max-width: 50vw;
    }
    a {
        color: white;
        text-decoration: none;
    }
    @media screen and (max-width: 768px) {
        padding: 10vh 0;
        font-size: 4vw;
        p {
            max-width: 80vw;
        }
    }
    @media screen and (max-width: 468px) {
        padding: 10vh 0;
        font-size: 5vw;
        p {
            max-width: 80vw;
        }
    }
    .stuckFooter {
        position: absolute;
        bottom: 2rem;
        width: 100%;
        display: flex;
        font-size: 1rem;
        justify-content: space-evenly;
        @media screen and (max-width: 468px) {
            font-size: 0.7rem;
            flex-wrap: wrap;
            p {
                width: 50%;
            }
        }
    }
`

import React, { useEffect, useState } from "react"
import logo from "./img/logo.png"
import {
    Header,
    Logo,
    Section,
    Footer,
    Dot,
    ScrollWrapper
} from "./components/styledComponents"
import { Global, css } from "@emotion/core"

const ScrollPos = ({ active = 0, length = 3, handleClick }) => {
    const dots = []

    for (let i = 0; i < length; i++) {
        dots.push(
            <Dot
                key={i}
                onClick={e => {
                    handleClick(e, i)
                }}
                className={active === i && "active"}
            />
        )
    }
    return <ScrollWrapper>{dots}</ScrollWrapper>
}

function App() {
    const [active, setActive] = useState(0)

    useEffect(d => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    })

    const handleClick = (e, i) => {
        let height = window.outerHeight * i
        window.scrollTo({ top: height, behavior: "smooth" })
    }

    const handleScroll = e => {
        let scrollPos = window.scrollY,
            height = window.outerHeight
        const current = Math.round(scrollPos / height)
        if (current !== active) {
            setActive(current)
            console.log(current)
        }
    }

    return (
        <div className="App">
            <Global
                styles={theme =>
                    css`
                        * {
                            box-sizing: border-box;
                            font-family: "Roboto", sans-serif;
                        }
                        body,
                        .App {
                            scroll-snap-type: mandatory;
                            scroll-snap-points-y: repeat(100vh);
                            scroll-snap-type: y mandatory;
                        }
                    `
                }
            />
            <Header>
                <Logo src={logo} alt="" />
                <p>
                    KriNor bedriver konsultuppdrag inom främst olika
                    styrelseuppdrag, verksamhetsutveckling,
                    marknadspositionering, marknadsundersökningar, ledarskap,
                    och Lean utbildningar.
                </p>
            </Header>
            <Section>
                <div className="half imgBox" />
                <div className="half textBox">
                    <div>
                        <p>
                            KriNor arbetar också direkt i olika
                            produktionsflöden där man i regel jobbar utefter
                            denna uppdragsmetodik.
                        </p>
                        <ul>
                            <li>Kartläggning</li>
                            <li>Planering</li>
                            <li>Problembeskrivning</li>
                            <li>Problemlösning</li>
                            <li>Genomförandet</li>
                            <li>Uppföljning</li>
                        </ul>
                        <p>
                            Det som utmärker ett KriNor uppdrag är att vi,
                            tillsammans med våra kunders ledning och personal,
                            genomför förändringar som leder till bestående
                            förbättringar. KriNor styrkor är att genomföra och
                            säkra förändringar i aktiviteter och beteenden som
                            genererar hållbara och mätbara förbättringar.
                        </p>
                        <p>
                            Krinor hjälper även till att uppgradera befintliga
                            ISO Certifikat 9001 och ISO 14001.
                        </p>
                    </div>
                </div>
            </Section>
            <Footer>
                <p>
                    KriNor utgår ifrån Göteborg och Jönköping men agerar i EU
                    och dom nordiska länderna beroende på uppdragets art.
                </p>
                <div className="stuckFooter">
                    <p>
                        KriNor Consulting AB
                        <br />
                        559146-6080
                    </p>
                    <p>
                        Formskäraregatan 3<br />
                        412 61 Göteborg
                    </p>
                    <p>
                        Tel: 070 - 5979530
                        <br />
                        <a href="mailto:krister.norberg@krinor.se">
                            krister.norberg@krinor.se
                        </a>
                    </p>
                </div>
            </Footer>
            <ScrollPos active={active} handleClick={handleClick} />
        </div>
    )
}

export default App
